import styled from "@emotion/styled";
import Error from "@/components/common/Error";
import { HEADER_HEIGHT } from "@/constants/components";

function NotFoundPage() {
  return (
    <FullHeightError buttonText="처음으로 돌아가기" buttonHref="/">
      <div className="head2_b">죄송합니다</div>
      <div className="body2 gray800">
        찾을 수 없는 페이지를 요청하셨습니다.
        <br />
        요청하신 페이지의 주소가 변경, 삭제되어
        <br />
        찾을 수 없습니다.
      </div>
    </FullHeightError>
  );
}

const FullHeightError = styled(Error)`
  min-height: calc(var(--vh) * 100 - ${HEADER_HEIGHT}px);

  .title {
    padding-top: 32px;
  }

  .body2 {
    padding: 8px 0 80px;
    text-align: center;
  }
`;

export default NotFoundPage;
